import React from 'react'
import ScrollTo from '../../../../../../components/ScrollTo'
import Img from 'gatsby-image'
import usePageQuery from '../../pageQuery'

import { ContentHeader } from './style'
import useWidth from 'src/hooks/window/useWidth'

const Header = () => {
  const data = usePageQuery()

  const WIDTH_MD = 768
  const windowWidth = useWidth(300)

  return (
    <ContentHeader className='py-5 bg-black d-flex align-items-end align-items-md-center'>
      <div className='container'>
        <div className='row justify-content-center justify-content-md-start'>
          <div className='col-12 col-md-8 col-lg-6'>
            {windowWidth >= WIDTH_MD && (
              <div className='mb-md-3'>
                <Img fluid={data.logoInterBlack.fluid} alt='Logo Inter Black' />
              </div>
            )}
            <h1 className='fs-32 lh-40 fs-md-36 lh-md-45 fs-lg-48 lh-lg-60 fs-xl-64 lh-xl-80 fw-300 text-orange--extra'>
              <span className='d-block d-md-inline d-xl-block'>Exclusividade </span>
              que faz você acontecer
            </h1>
            <p className='fs-18 lh-22 fs-md-24 lh-md-30 fs-xl-36 lh-xl-45 fw-300 text-white mt-4'>
              Relacionamento feito sob <span className='d-md-block d-lg-inline d-xl-block'>medida <span className='d-lg-block d-xl-inline'>para ir além.</span></span>
            </p>
            <ScrollTo
              to='#beneficios'
              section='dobra_01'
              sectionName='Inter Black. Exclusividade para você ir além'
              elementName='Conhecer o Inter Black'
            >
              <button
                className='btn btn-orange--extra rounded-2 fs-14 fw-600 text-none mt-3'
              >
                Conhecer o Inter Black
              </button>
            </ScrollTo>
          </div>
        </div>
      </div>
    </ContentHeader>
  )
}

export default Header
