import React from 'react'
import { useLocation } from '@reach/router'
import queryString from 'query-string'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import Layout from './../../../../layouts/BaseLayout'

import Header from './sections/header/_index'
import ComoSerInterBlack from './sections/como-ser-inter-black/_index'
import Beneficios from './sections/beneficios/_index'
import CataoMasterComCashback from './sections/cartão-master-com-cashback/_index'
import Contatos from './sections/contatos/_index'
import SeuAntesEDepois from './sections/seu-antes-e-depois/_index'
import Faq from './sections/faq/_index'

import pageContext from './pageContext.json'

import { Wrapper } from './style'

function InterBlack () {
  const location = useLocation()
  const [ sendDatalayerEvent ] = useDataLayer()
  const queryParams = queryString.parse(location.search)

  return (
    <Wrapper>
      <Layout pageContext={pageContext} modalOpenInterBlackDefaultValue={queryParams.fo}>
        <Header />
        <ComoSerInterBlack sendDatalayerEvent={sendDatalayerEvent} />
        <Beneficios sendDatalayerEvent={sendDatalayerEvent} />
        <CataoMasterComCashback sendDatalayerEvent={sendDatalayerEvent} />
        <Contatos sendDatalayerEvent={sendDatalayerEvent} />
        <SeuAntesEDepois sendDatalayerEvent={sendDatalayerEvent} />
        <Faq />
      </Layout>
    </Wrapper>
  )
}

export default InterBlack
