import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

import HeroSM from '../../assets/images/hero-seu-antes-depois.png'
import HeroMD from '../../assets/images/hero-seu-antes-depois-md.png'
import HeroLG from '../../assets/images/hero-seu-antes-depois-lg.png'

export const BeforeAndAfter = styled.section`
  background-color: #161616;
  background-image: url(${HeroSM});
  background-repeat: no-repeat;
  background-size: cover;

  @media ${device.tablet} {
    height: 371px;
    background-image: url(${HeroMD});
    background-repeat: no-repeat;
    background-size: cover;
  }

  @media ${device.desktopLG} {
    background-image: url(${HeroLG});
    background-repeat: no-repeat;
    background-size: cover;
  }

  @media ${device.desktopXL} {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/bg-1440/image.webp');
    background-repeat: no-repeat;
    background-size: cover;
  }

  u {
    cursor: pointer;
  }
`
