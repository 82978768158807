import React from 'react'
import Link from 'components/GatsbyLinkWrapper'
import * as URLs from 'config/api/Urls'

import { BeforeAndAfter } from './style'
import { DataLayerProps } from 'src/hooks/useDataLayer/types'

const SeuAntesEDepois = ({ sendDatalayerEvent }: DataLayerProps) => {
  return (
    <BeforeAndAfter className='d-flex align-items-center'>
      <div className='container py-5'>
        <div className='col-lg-10 offset-lg-1 col-xl-12 offset-xl-0'>
          <h2 className='fs-32 lh-40 fs-lg-36 lh-lg-45 text-md-center text-white fw-600 font-sora'>
            <span className=''>Seu antes e depois</span> também é Inter
          </h2>
          <p className='fs-18 lh-28 fs-md-20 lg-md-25 fs-xl-24 lh-xl-30 text-md-center text-white fw-400'>
            <span className='d-block d-md-inline d-xl-block'>
              Quer construir seu caminho para chegar até o Inter Black? Conte com o relacionamento
              <Link
                to='/pra-voce/relacionamento/inter-one/'
                className='fw-600 text-orange--extra'
                title='Inter One'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_09',
                    section_name: 'Seu antes e depois também é Inter',
                    element_action: 'click button',
                    element_name: 'Inter One',
                    redirect_url: URLs.SITE_BASE_URL + '/pra-voce/relacionamento/inter-one/',
                  })
                }}
              >
                {' '}<u>One</u>
              </Link>.
            </span>
          </p>

          <p className='fs-18 lh-28 fs-md-20 lg-md-25 fs-xl-24 lh-xl-30 text-md-center text-white fw-400'>
            E, se seus passos te levarem mais longe, conheça o
            <Link
              to='/pra-voce/relacionamento/inter-win/'
              className='fw-600 text-orange--extra'
              title='Conheça o Win'
              target='_blank' rel='noreferrer'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_09',
                  section_name: 'Seu antes e depois também é Inter',
                  element_action: 'click button',
                  element_name: 'Conheça o Win',
                  redirect_url: `${window.location.origin}pra-voce/relacionamento/inter-win/`,
                })
              }}
            >
              {' '}<u>Win</u>
            </Link>.
          </p>
        </div>
      </div>
    </BeforeAndAfter>
  )
}

export default SeuAntesEDepois
