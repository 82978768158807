import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'

export const HowToBeInterBlack = styled.section`
  height: 800px;

  background: url('https://central-imagens.bancointer.com.br/images-without-small-versions/inter-black-dobra2/image.webp');
  background-repeat: no-repeat;
  background-position-y: 30px;
  background-position-x: center;
  background-size: auto;
  background-color: ${grayscale['500']};

  @media ${device.tablet} {
    background: url('https://central-imagens.bancointer.com.br/images-without-small-versions/inter-black-dobra2-768/image.webp');
    background-repeat: no-repeat;
    background-position-y: 30px;
    background-position-x: center;
    background-size: auto;
    background-color: ${grayscale['500']};
    height: 739px;
  }

  @media ${device.desktopLG} {
    background: url('https://central-imagens.bancointer.com.br/images-without-small-versions/mulher-grafismo-1440/image.webp');
    background-repeat: no-repeat;
    background-position-y: 40px;
    background-position-x: 40px;
    background-size: auto;
    background-color: ${grayscale['500']};
    height: 689px;
  }

  @media ${device.desktopXL} {
    background: url('https://central-imagens.bancointer.com.br/images-without-small-versions/mulher-grafismo-1440/image.webp');
    background-repeat: no-repeat;
    background-position-y: 50px;
    background-position-x: 100px;
    background-size: auto;
    background-color: ${grayscale['500']};
    height: 691px;
  }



`
export const Button = styled.button`
  @media ${device.tablet} {
    width: 30%;
  }

  @media ${device.desktopLG} {
    width: 37%;
  }
`
