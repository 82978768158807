import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const SectionDark = styled.section`
  background: #212020;

  @media ${device.tablet} {
    background: url('https://central-imagens.bancointer.com.br/images-without-small-versions/dobra5-bg/image.webp');
    background-repeat: no-repeat;
    background-size: cover;
  }

  @media ${device.desktopLG} {
    height: 547px;
  }

 hr {
  border: 1px solid #6A6C72;
  width: 45%;
  display: flex;

  @media ${device.tablet} {
    width: 63%;
  }

  @media ${device.desktopLG} {
    display: none;
  }
 }

  .div-border {
    @media ${device.desktopLG} {
      position: relative;
      :before {
        display: block;
        content: '';
        width: 1px;
        height: 156px;
        background-color: #6A6C72;
        position: absolute;
        left: -24px;
      }
    }
  }

 .whatsapp {
    path {
      fill: #ffffff;
    }
  }
`
