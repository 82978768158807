import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'

export const MasterCardWithCashback = styled.section`
  height: 1120px;
  background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/homem-grafismo-mobile/image.png');
  background-repeat: no-repeat;
  background-position: top;
  background-size: contain;
  background-color: ${grayscale[500]};

  @media (min-width: ${breakpoints.md}) {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/dobra4-homem-com-celular/image.webp');
    background-repeat: no-repeat;
    background-size: 391px;
    background-position: top;
    height: 800px;
  }

  @media (min-width: ${breakpoints.lg}) {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/dobra4-homem-com-celular/image.webp');
    background-repeat: no-repeat;
    background-size: 393px;
    background-position-x: 40px;
    background-position-y: center;
    height: 672px;
  }

  @media (min-width: ${breakpoints.xl}) {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/dobra4-homem-com-celular/image.webp');
    background-repeat: no-repeat;
    background-size: 600px;
    background-position-x: 40px;
    background-position-y: center;
    height: 817px;
  }

  @media (min-width: ${breakpoints.xxxl}) {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/dobra4-homem-com-celular/image.webp');
    background-repeat: no-repeat;
    background-size: 600px;
    background-position-x: 80px;
    background-position-y: center;
    height: 817px;
  }

  @media (min-width: ${breakpoints.lg}) {
    .icon {
      margin-bottom: 27px;
    }

    .icon:last-child {
      margin-bottom: 32px
    }
  }
`

export const Button = styled.button`
  @media (min-width: ${breakpoints.md}) {
    width: 30%;
  }

  @media (min-width: ${breakpoints.lg}) {
    width: 50%;
  }
`
