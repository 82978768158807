import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const ContentHeader = styled.section`
  height: 695px;

  background: url('https://central-imagens.bancointer.com.br/images-without-small-versions/inter-black-360/image.webp') no-repeat top center;
  background-size: contain;

  h1 {
    font-family: 'inter', Helvetica, sans-serif !important;
    font-weight: 300 !important;
  }

  @media ${device.tablet} {
    background: url('https://central-imagens.bancointer.com.br/images-without-small-versions/inter-black-768/image.webp') no-repeat right center;
    height: 482px;
  }

  @media ${device.desktopLG} {
    background: url('https://central-imagens.bancointer.com.br/images-without-small-versions/inter-black-1024/image.webp') no-repeat right center;
    height: 729px;
  }

  @media ${device.desktopXL} {
    background: url('https://central-imagens.bancointer.com.br/images-without-small-versions/inter-black-1440/image.webp') no-repeat right center;
  }

  button {
    @media ${device.tablet} {
      width: 55%;
    }
  }
`
