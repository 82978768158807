import React, { useState } from 'react'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import { Circle, Card, ContentText } from './style'
import ImgDetails from '../../assets/images/card-details.png'

type BeneficiosProps = {
  title: string;
  description: string;
  image: string;
  index: number;
}

const CardContent = ({ item, index }: BeneficiosProps) => {
  const [ card, setCard ] = useState(false)
  const [ close, setClose ] = useState(false)
  const [ isIndex, setIsIndex ] = useState<number | undefined>()

  const HandleClick = (index: number) => {
    setIsIndex(index)
    index === isIndex && setCard(card)
  }

  return (
    <Card
      className={`${!card && index === isIndex && close ? 'bg' : 'bg-reverse'} mr-md-3`}
      style={{ backgroundImage: `url(${item?.image})` }}
      key={item?.title}
      onClick={() => {
        setCard(card)
        HandleClick(index)
        setClose(!close)
      }}
    >
      {!card && index === isIndex && close && <div className='text-card' />}
      <Circle className={`${!card && index === isIndex && close ? 'open' : 'close'} d-flex align-items-center justify-content-center`}>
        <OrangeIcon
          size='MD' color={!card && index === isIndex && close ? '#FFFFFF' : '#FF7A00'}
          icon={!card && index === isIndex && close ? 'exit' : 'add'}
        />
      </Circle>
      <ContentText className={`${!card && index === isIndex && close ? 'top' : ''}`}>
        {
          !card && index === isIndex && close
          ? (
            <div>
              <h3 className='title mb-0 font-sora' dangerouslySetInnerHTML={{ __html: item?.title }} />
              <p className='descritpion font-sora' dangerouslySetInnerHTML={{ __html: item?.description }} />
            </div>
          )
          : (
            <div>
              <h3 className='title mb-0 font-sora' dangerouslySetInnerHTML={{ __html: item?.title }} />
              <p className='fs-14 lh-22 fw-600 text-white font-sora'>Saber mais</p>
            </div>
          )
        }
      </ContentText>
      {!card && index === isIndex && close && <img className='detail' src={ImgDetails} />}
    </Card>
  )
}

export default CardContent
