import React from 'react'
import { DataLayerProps } from 'src/hooks/useDataLayer/types'
import OrangeDsIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import { SectionDark } from './style'

const url = 'https://inter.co/atendimento-chat/?tipoAssunto=divulgacao&assunto=conta-inter-black'

const Contato = ({ sendDatalayerEvent }: DataLayerProps) => {
  return (
    <SectionDark className='py-5 bg-black d-flex align-items-center'>
      <div className='container'>
        <div className='row align-items-center justify-content-md-center'>
          <div className='col-12'>
            <h2 className='fs-32 lh-40 fs-md-32 lh-md-40 fs-lg-36 lh-lg-45 text-white fw-600 text-md-center'>
              Quer saber mais sobre o Inter Black?
            </h2>
            <p className='fs-16 lh-19 fs-md-18 lh-md-22 fs-lg-18 lh-lg-22 text-white fw-400 text-md-center mb-md-4'>
              Entre em contato com o nosso time para tirar dúvidas nos canais oficiais de atendimento.
            </p>
          </div>

          <div className='col-12 col-md-8 col-lg-6 col-xl-5 mt-4'>
            <h3 className='fs-20 lh-25 text-orange--extra fw-600'>Quero ser cliente Inter Black</h3>
            <div className='d-flex align-items-center mb-3'>
              <div className='mr-3'>
                <OrangeDsIcon size='MD' color='#ffffff' icon='phone' />
              </div>
              <p className='fs-16 lh-19 text-white fw-400 mb-0'>
                Entre em contato pelo telefone
                <span className='text-orange--extra'>
                  <a
                    href='tel:3130034070'
                  >
                    {' '}<span className='d-block d-md-inline'>(31) 3003 - 4070</span>
                  </a>
                </span>
              </p>
            </div>
            <div className='d-flex align-items-center mb-4'>
              <div className='mr-3'>
                <OrangeDsIcon size='MD' color='#ffffff' icon='email' />
              </div>
              <p className='fs-16 lh-19 text-white fw-400 mb-0'>
                Envie uma mensagem no{' '}
                <a
                  href={url}
                  target='__blank'
                  rel='noreferrer'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_08',
                      section_name: 'Para saber mais, entre em contato conosco.',
                      element_action: 'click button',
                      element_name: 'chat do nosso app',
                      redirect_url: url,
                    })
                  }}
                >
                  chat
                </a>
              </p>
            </div>
          </div>

          <hr />

          <div className='col-12 col-md-8 col-lg-6 col-xl-5 mt-4 div-border'>
            <h3 className='fs-20 lh-25 text-orange--extra fw-600'>Já sou cliente Inter Black</h3>
            <div className='d-flex align-items-center mb-3'>
              <div className='mr-3'>
                <OrangeDsIcon size='MD' color='#ffffff' icon='whatsapp' className='whatsapp' />
              </div>
              <p className='fs-16 lh-19 text-white fw-400 mb-0'>
                WhatsApp:
                <span className='text-orange--extra'>
                  <a
                    href='https://api.whatsapp.com/send?phone=553130038477'
                  >
                    {' '}(31) 3003 - 8477
                  </a>
                </span>
              </p>
            </div>
            <div className='d-flex align-items-center mb-3'>
              <div className='mr-3'>
                <OrangeDsIcon size='MD' color='#ffffff' icon='email' />
              </div>
              <p className='fs-16 lh-19 text-white fw-400 mb-0'>
                Mande um e-mail para o advisor
              </p>
            </div>
            <div className='d-flex align-items-center'>
              <div className='mr-3'>
                <OrangeDsIcon size='MD' color='#ffffff' icon='video-camera' />
              </div>
              <p className='fs-16 lh-19 text-white fw-400 mb-0'>
                Agende uma videoconferência com o advisor pelo app
              </p>
            </div>
          </div>
        </div>
      </div>
    </SectionDark>
  )
}
export default Contato
