import React from 'react'
import { DataLayerProps } from 'src/hooks/useDataLayer/types'
import OpenModal from 'src/components/OpenModal'

import OrangeDsIcon from 'src/components/UI/MarkdownIcon/InterCoIcons'
import ClienteInterJSON from '../../assets/data/cliente-inter-black.json'

import { MasterCardWithCashback, Button } from './style'

interface IClienteInter {
  icon: string;
  description: string;
}

const CataoMasterComCashback = ({ sendDatalayerEvent }: DataLayerProps) => {
  return (
    <MasterCardWithCashback className='py-5 d-flex align-items-end align-items-lg-center'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-between'>
          <div className='col-12 col-lg-6 offset-lg-6 px-lg-0'>
            <h2 className='fs-32 lh-40 fs-md-32 lh-md-40 fs-xl-36 lh-xl-45 text-white text-md-center text-lg-left fw-600 font-sora mb-4'>
              Cliente Inter Black aproveita <span className='d-md-block'>o melhor com exclusividade</span>
            </h2>
            <p className='fs-16 lh-22 fs-md-20 lh-md-28 text-white text-md-center text-lg-left fw-400 mb-md-4'>
              Receba orientações do seu advisor sobre os serviços <span className='d-md-block'>do Super App que fazem sentido para sua vida:</span>
            </p>

            <div className='row'>
              {ClienteInterJSON.map((item: IClienteInter, index: number) => (
                <div className='col-12 col-md-6 col-lg-12 mb-4 mb-lg-0' key={index}>
                  <div className='d-flex align-items-center icon'>
                    <div className='mr-2'>
                      <OrangeDsIcon size='MD' color='#FF7A00' icon={item.icon} />
                    </div>
                    <h3 className='fs-14 lh-17 fs-md-16 lh-md-19 fs-xl-20 lh-xl-24 text-white fw-400 mb-0'>
                      {item.description}
                    </h3>
                  </div>
                </div>
              ))}
            </div>

            <OpenModal to='modal-open-inter-black-contact-form' className='d-flex justify-content-md-center justify-content-lg-start'>
              <Button
                aria-label='Quero ser Inter Black'
                className='btn btn-orange--extra rounded-2 fs-14 fw-600 text-none mt-3'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_03',
                    section_name: 'Como ser Inter Black',
                    element_action: 'click button',
                    element_name: 'Quero ser Inter Black',
                  })
                }}
              >
                Quero ser Inter Black
              </Button>
            </OpenModal>
          </div>
        </div>
      </div>
    </MasterCardWithCashback>
  )
}

export default CataoMasterComCashback
